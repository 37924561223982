import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '~/components/molecules/Layout';
import { SEO } from '~/components/atoms/SEO';
// eslint-disable-next-line import/no-cycle
import SportsPage from '../SportsPage/SportsPage';
import { TranslationContext } from '~/utils/styles/utils';

const Sports: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('SeoTitle')} metaDescription={t('SeoMetaDescription')} />
      <TranslationContext.Provider value={t}>
        <SportsPage />
      </TranslationContext.Provider>
    </Layout>
  );
};

export const useTranslationContext = () => React.useContext(TranslationContext);

export default Sports;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["sportspage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import SabineJPG from '@assets/images/sabine-mobile.jpg';
import SchledererJPG from '@assets/images/schlederer.jpg';
import DGSPJPG from '@assets/images/dgsp-non-green.png';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainer,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/sportmedizin-laktattest';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/sports-medicine-lactatetest';

const SportsMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('SportsContent1') || tEn('SportsContent1'),
            }}
          />
          <FlexContainer>
            <ImageContainer>
              <Image src={SabineJPG} alt="Dr. med. Uez" width={350} />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('SportsContent2') || tEn('SportsContent2'),
                }}
              />
            </List>
          </FlexContainer>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('SportsContent3') || tEn('SportsContent3'),
                }}
              />
            </List>
            <ImageContainer>
              <Image src={SchledererJPG} alt="Dr. Schlederer" width={370} />
            </ImageContainer>
          </FlexContainer>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('SportsContent4') || tEn('SportsContent4'),
                }}
              />
            </List>
            <ImageContainer>
              <Image src={DGSPJPG} alt="DGSP" width={300} />
            </ImageContainer>
          </FlexContainer>
        </ContainerSmall>
      </Container>
    </>
  );
};

export default SportsMain;
